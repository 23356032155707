
var arr = 
[
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    per: "home"
  },
  {
    title: 'المستخدمين',
    route: 'users',
    icon: 'UsersIcon',
    per: "home"
  },
  {
    title: 'الطلبات',
    route: 'orders',
    icon: 'ShoppingCartIcon',
    per: "home"
  },
  {
    title: 'الاراء والاقتراحات',
    route: 'feedback',
    icon: 'EditIcon'
  },
  {
    title: 'الاجازات والعطلات',
    route: 'freedays',
    icon: 'CalendarIcon',
    per: "freedays"
  },
  {
    title: 'الخدمات',
    route: 'services',
    icon: 'ShoppingCartIcon',
    per: "home"
  },
  {
    title: 'الاعدادات',
    route: 'settings',
    icon: 'SettingsIcon',
    per: "home"
  },
  {
    title: 'الشركاء',
    icon: 'UserCheckIcon',
    tagVariant: 'light-warning',
    children: [
        {
            title: 'استعراض الشركاء',
            route: 'resellers',
        },
        {
            title: 'الزيارات',
            route: 'visits',
        },
        {
            title: 'المواعيد',
            route: 'calendar',
        },
        {
            title: 'المهام',
            route: 'todo',
        },
    ],
  },
  {
    title: 'اجهزة البصمة الجميلة',
    route: 'devices',
    icon: 'ServerIcon',
    per: "home"
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
]

export default arr
